/** @jsx jsx */
import { jsx } from "theme-ui";

import { StringParam, useQueryParam } from "use-query-params";
import AbhyasiEntity from "gatsby-plugin-hfn-profile/components/SrcmEntities/AbhyasiEntity";
import { abhyasStage } from "sites-common/utils/validations";
import { doFormatShortDate } from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import { DefaultButton } from "office-ui-fabric-react";
import SearchAbhyasis from "../../components/SearchAbhyasis";
import DLWLayout from "../../layouts/default";

const colDefns = [
  ["", (r) => <AbhyasiEntity abhyasiId={r.ref} display=" " item={r} />],
  ["Name", "name"],
  [
    "Hearfulness Id",
    (r) => {
      return (
        <div>
          {" "}
          {r.ref}
          {r.record_type === "s" && (
            <div>
              <DefaultButton
                iconProps={{ iconName: "Zoom" }}
                target="_blank"
                text="Pending Request"
                href={`/central-team-approvals/?id=${r.id}`}
              />
            </div>
          )}
        </div>
      );
    },
  ],
  ["Abhyas Stage", (r) => abhyasStage(r.record_type, r.ref)],
  [
    "Started Practice",
    (r) => (r.date_of_joining ? doFormatShortDate(r.date_of_joining) : ""),
  ],
  ["Gender", "gender"],
  ["Age", "age"],
  ["City", "city"],
  ["Email", "email"],
  ["Mobile", "mobile"],
  ["Phone", "phone"],
];

function SearchAbhyasisPage() {
  const [k] = useQueryParam("k", StringParam);
  const [q] = useQueryParam("q", StringParam);

  return (
    <DLWLayout>
      {!(!!k && !!q) && <SearchAbhyasis colDefns={colDefns} />}
      {!!k && !!q && (
        <SearchAbhyasis defaultQuery={[k, q]} colDefns={colDefns} />
      )}
    </DLWLayout>
  );
}

export default SearchAbhyasisPage;
